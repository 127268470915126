.checkoutPage {
	-webkit-print-color-adjust: exact;
	@apply md:tw-pb-4 tw-divide-y tw-divide-gray-400 md:tw-divide-y-0 print:tw-py-0 print:md:tw-flex print:md:tw-static;
}
.stickySidebar {
	@apply tw-sticky tw-space-y-4 tw-top-4;
	:global(.Tabccordion--accordion.Tabccordion--orderSummary [id$='tabccordion-tab0']) {
		@apply tw-pt-0 #{'!important'};
	}
}
:global(.is-engage-site) {
	.stickySidebar {
		top: calc(var(--devBeam-height) + var(--engage-toolbar-height) + 1rem);
	}
}
